import React from "react";
import { JobDetail, JobDetailShort } from "../../lib/Api";
import { useAppSelector } from "../../lib/hooks";
import TreeTrimOutageInfoModal from "./TreeTrimOutageInfoModal";

const AddActivity: React.FC<{
  job: JobDetail | JobDetailShort;
}> = ({ job }) => {
  return (
    <div className="w-full m-auto">
      <div className="flex justify-center mt-1">
        <TreeTrimOutageInfoModal job={job} />
      </div>
    </div>
  );
};

const TreeTrimOutageInfoList: React.FC<{
  job: JobDetail | JobDetailShort;
}> = ({ job }) => {
  const treeTrimActivities = useAppSelector(
    (state) => state.treeTrim.outageInfo
  );

  //empty array of activities
  if (treeTrimActivities?.length === 0) {
    return (
      <>
        <div className="py-0.5">No outage info found</div>
        <AddActivity job={job} />
      </>
    );
  }

  //TODO: check display for mobile
  return (
    <>
      <div className="w-full flex flex-wrap max-h-screen h-full overflow-x-auto">
        <table className="table-auto bg-white w-full text-black border mb-2">
          <thead className="bg-gray-200">
            <tr className="cursor-pointer">
              <th
                scope="col"
                className="px-0.25 md:px-1 py-0.5 whitespace-no-wrap"
              >
                Tree Location
              </th>
              <th
                scope="col"
                className="px-0.25 md:px-1 py-0.5 whitespace-no-wrap"
              >
                Line Type
              </th>
              <th
                scope="col"
                className="px-0.25 md:px-1 py-0.5 whitespace-no-wrap"
              >
                Tree Species
              </th>
              <th
                scope="col"
                className="px-0.25 md:px-1 py-0.5 whitespace-no-wrap"
              >
                Scenario
              </th>
              <th
                scope="col"
                className="px-0.25 md:px-1 py-0.5 whitespace-no-wrap"
              >
                Pre-Outage?
              </th>
              <th
                scope="col"
                className="px-0.25 md:px-1 py-0.5 whitespace-no-wrap"
              >
                Preventable?
              </th>
              <th
                scope="col"
                className="px-0.25 md:px-1 py-0.5 whitespace-no-wrap"
              >
                Notes
              </th>
            </tr>
          </thead>
          <tbody>
            {treeTrimActivities?.map((tt) => (
              <tr className="hover:bg-gray-200 cursor-pointer" key={tt.id}>
                <td className="border text-center py-0.5 px-0.25 md:px-1">
                  {tt.treeLocation}
                </td>
                <td className="border px-0.25 md:px-1 text-center py-0.5">
                  {tt.lineType}
                </td>
                <td className="border px-0.25 md:px-1 text-center py-0.5">
                  {tt.treeSpecies}
                </td>
                <td className="border px-0.25 md:px-1 text-center py-0.5">
                  {tt.scenario}
                </td>
                <td className="border px-0.25 md:px-1 text-center py-0.5">
                  {tt.maintenanceSpecMet ? "Yes" : "No"}
                </td>
                <td className="border px-0.25 md:px-1 text-center py-0.5">
                  {tt.maintenancePreventOutage ? "Yes" : "No"}
                </td>
                <td className="border px-0.25 md:px-1 text-center py-0.5">
                  {tt.notes}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <AddActivity job={job} />
    </>
  );
};

export default TreeTrimOutageInfoList;
