export type TreeTrimActivityKey = keyof TreeTrimActivity;

export interface TreeTrimActivity {
  id: string;
  jobId: string;
  circuit: string;
  jobCreateDate: string;
  jobClosureDate?: string;
  activityType: string;
  activityCode: string;
  quantity: number;
  notes: string;
  createUser: string;
  createDate: string;
}

export const validateTreeTrimActivity = (
  tt: TreeTrimActivity
): TreeTrimActivityKey[] => {
  const errors: TreeTrimActivityKey[] = [];

  if (!tt.activityType) {
    errors.push("activityType");
  }

  if (!tt.activityCode) {
    errors.push("activityCode");
  }

  if (!tt.quantity) {
    errors.push("quantity");
  }

  return errors;
};

export const initTreeTrimActivity = (): TreeTrimActivity => {
  return {
    id: "",
    jobId: "",
    circuit: "",
    jobCreateDate: "",
    jobClosureDate: "",
    activityType: "",
    activityCode: "",
    quantity: 0,
    notes: "",
    createUser: "",
    createDate: "",
  };
};
