import { useState } from "react";
import { DropdownOption } from "../../common/models/DropdownOption";
import {
  getActivityTypeOptions,
  getActivityCodeOptions,
} from "../../lib/api/treeTrimApi";
import {
  TreeTrimActivity,
  TreeTrimActivityKey,
} from "../../lib/models/TreeTrimActivity";
import { DropdownControl } from "../controls/DropdownControl";
import { NumericControl } from "../controls/NumericControl";
import { TextAreaControl } from "../controls/TextAreaControl";
import TreeTrimFormColumn from "./TreeTrimFormColumn";
import TreeTrimFormRow from "./TreeTrimFormRow";

const TreeTrimActivityForm: React.FC<{
  treeTrimActivity: TreeTrimActivity;
  onUpdate: (value: TreeTrimActivity) => void;
  errors: TreeTrimActivityKey[];
}> = ({ treeTrimActivity, onUpdate, errors }) => {
  const [selectedActivityType, setSelectedActivityType] = useState<string>(
    treeTrimActivity.activityType
  );

  const getSelectedActivityTypeOption = (
    activityType: string
  ): DropdownOption | null => {
    return (
      getActivityTypeOptions().find(
        (option) => option.value === activityType
      ) || null
    );
  };

  const getSelectedActivityCodeOption = (
    activityType: string,
    activityCode: string
  ): DropdownOption | null => {
    const activityCodeOptions = getActivityCodeOptions(activityType);
    return (
      activityCodeOptions.find((option) => option.value === activityCode) ||
      null
    );
  };

  const fieldHasError = (field: string) => {
    return errors?.find((e) => e === field) !== undefined;
  };

  return (
    <>
      <TreeTrimFormRow>
        <DropdownControl
          className="w-full"
          label="Activity Type"
          required={true}
          error={fieldHasError("activityType")}
          value={getSelectedActivityTypeOption(treeTrimActivity?.activityType)}
          options={getActivityTypeOptions()}
          setValue={(value) => {
            onUpdate({
              ...treeTrimActivity,
              activityType: value,
              activityCode: "",
            });
            setSelectedActivityType(value);
          }}
        />
      </TreeTrimFormRow>
      <TreeTrimFormRow>
        <TreeTrimFormColumn className="md:pr-0.5">
          <DropdownControl
            label="Activity Code"
            required={true}
            error={fieldHasError("activityCode")}
            value={getSelectedActivityCodeOption(
              selectedActivityType ?? treeTrimActivity?.activityType,
              treeTrimActivity.activityCode
            )}
            options={getActivityCodeOptions(selectedActivityType)}
            setValue={(value) => {
              onUpdate({ ...treeTrimActivity, activityCode: value });
            }}
          />
        </TreeTrimFormColumn>
        <TreeTrimFormColumn className="pt-0.5 md:pl-0.5 md:pt-0">
          <NumericControl
            label="Unit"
            placeholder="#"
            required={true}
            error={fieldHasError("quantity")}
            value={treeTrimActivity.quantity}
            setValue={(value) => {
              onUpdate({ ...treeTrimActivity, quantity: value });
            }}
          />
        </TreeTrimFormColumn>
      </TreeTrimFormRow>
      <TreeTrimFormRow>
        <TextAreaControl
          label="Notes"
          value={treeTrimActivity.notes}
          error={fieldHasError("notes")}
          setValue={(value) => {
            onUpdate({ ...treeTrimActivity, notes: value });
          }}
        />
      </TreeTrimFormRow>
    </>
  );
};

export default TreeTrimActivityForm;
