import React from "react";
import Popup from "reactjs-popup";
import * as util from "../../lib/Utility";
import * as api from "../../lib/Api";
import { useAppSelector, useAppDispatch } from "../../lib/hooks";
import { setLoading } from "../../features/jobSlice";
import clsx from "clsx";

type Action = "Cancel" | "Close";
type CCJobType = "TreeTrim" | "DamageAssess" | "NonOutage";

const CancelCloseModal = (props: {
  job: api.JobDetail;
  afterSubmit: Function;
  action: Action;
  jobType: CCJobType;
}) => {
  const [disp, setDisp] = React.useState("");
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role.value);

  const handleSubmit = async () => {
    cancelOrClose();
  };

  const cancelOrClose = async () => {
    dispatch(setLoading(true));
    const crewsRes = await api.getCrewAssignmentByJobId([props.job.jobId]);
    if (crewsRes.tag === "ok") {
      const jobList = Array.isArray(crewsRes.data.job.jobList)
        ? crewsRes.data.job.jobList[0]
        : crewsRes.data.job.jobList;

      let crewsIds: string[] = [];
      if (Array.isArray(jobList.crewAssignments)) {
        crewsIds = [...jobList.crewAssignments.map((x) => x.crewId)];
      } else {
        crewsIds = [jobList.crewAssignments.crewId];
      }
      const pReq: api.CrewAssignmentStatusUpdateReq = {
        msgId: props.job.jobId,
        jobId: props.job.jobId,
        crewAssignmentStatus: "CA",
        comments: `${role?.name} ${
          props.action === "Cancel" ? "cancelled" : "closed"
        } job ${props.job.jobId} ${
          props.action === "Cancel" && `with Disposition: ${disp}`
        }`,
        disposition: disp || undefined,
      };
      const preempts = await Promise.allSettled(
        crewsIds
          .filter((x) => x !== "")
          .map((x) => api.updateCrewAssignmentStatus(x, pReq))
      );
      if (
        preempts.every((x) => x.status === "fulfilled" && x.value.tag === "ok")
      ) {
        const res = await api.updateJob(props.job.jobId, {
          msgId: props.job.jobId,
          jobStatus: {
            jobStatus: props.action,
            comments: `${role?.name} ${
              props.action === "Cancel" ? "cancelled" : "closed"
            } job ${props.job.jobId} ${
              props.action === "Cancel" && `with Disposition: ${disp}`
            }`,
          },
          jobComments: `${role?.name} ${
            props.action === "Cancel" ? "cancelled" : "closed"
          } job ${props.job.jobId} ${
            props.action === "Cancel" && `with Disposition: ${disp}`
          }`,
        });
        if (res.tag === "ok") {
          alert(`Job ${props.action} Successful`);
          props.afterSubmit();
        } else {
          alert(
            "Job update failed, check your internet connection. You can resend the request through the Drafts page"
          );
          props.afterSubmit();
        }
      } else {
        alert(
          "Job update failed, check your internet connection. You can resend the request through the Drafts page"
        );
        props.afterSubmit();
      }
    } else {
      alert(
        "Job update failed, check your internet connection. You can resend the request through the Drafts page"
      );
      dispatch(setLoading(false));
    }
  };

  const disableConfirm = (action: Action) => {
    if (action === "Close") {
      if (props.jobType === "DamageAssess") {
        if (disp === "") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      if (disp === "") {
        return true;
      } else {
        return false;
      }
    }
  };

  const disableButton = () => {
    if (util.cancelCloseStatusCodes.includes(props.job.jobStatus)) {
      if (props.job.jobType === "XCURR") {
        if (props.job.jobSubType === "CONF" && props.job.causeCode) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      <Popup
        closeOnDocumentClick={true}
        modal={true}
        disabled={disableButton()}
        overlayStyle={{ cursor: "default" }}
        contentStyle={{ cursor: "default" }}
        onClose={() => setDisp("")}
        onOpen={() => setDisp("")}
        className="responsive-modal"
        trigger={
          <button
            className={clsx(
              "mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white",
              disableButton() && "disabled"
            )}
            disabled={disableButton()}
          >
            {props.action} Job
          </button>
        }
      >
        {(closeModal) => (
          <div className="text-black py-1 flex justify-center flex-col">
            {props.action === "Close" ? (
              <p className="mb-1 text-center">
                Are you sure you want to close this job?
              </p>
            ) : (
              <p className="mb-1 text-center">Choose a reason</p>
            )}
            {props.action === "Close" && props.jobType === "DamageAssess" && (
              <button
                className={`${
                  disp === "OK"
                    ? "bg-white border border-dte-500 text-dte-500"
                    : "border border-dte-500 bg-dte-500 text-white"
                } mx-0.5 my-0.5 py-0.5 px-0.75`}
                onClick={() => setDisp("OK")}
              >
                OK on Arrival
              </button>
            )}
            {props.action === "Cancel" && props.jobType === "TreeTrim" && (
              <>
                <button
                  className={`${
                    disp === "OK"
                      ? "bg-white border border-dte-500 text-dte-500"
                      : "border border-dte-500 bg-dte-500 text-white"
                  } mx-0.5 my-0.5 py-0.5 px-0.75`}
                  onClick={() => setDisp("OK")}
                >
                  OK on Arrival
                </button>
                <button
                  className={`${
                    disp === "FOR"
                      ? "bg-white border border-dte-500 text-dte-500"
                      : "border border-dte-500 bg-dte-500 text-white"
                  } mx-0.5 my-0.5 py-0.5 px-0.75`}
                  onClick={() => setDisp("FOR")}
                >
                  Foreign WIRE/PLD
                </button>
                <button
                  className={`${
                    disp === "CJOB"
                      ? "bg-white border border-dte-500 text-dte-500"
                      : "border border-dte-500 bg-dte-500 text-white"
                  } mx-0.5 my-0.5 py-0.5 px-0.75`}
                  onClick={() => setDisp("CJOB")}
                >
                  C-JOB
                </button>
                <button
                  className={`${
                    disp === "MAINT"
                      ? "bg-white border border-dte-500 text-dte-500"
                      : "border border-dte-500 bg-dte-500 text-white"
                  } mx-0.5 my-0.5 py-0.5 px-0.75`}
                  onClick={() => setDisp("MAINT")}
                >
                  OK til Maintenace
                </button>
                <button
                  className={`${
                    disp === "SERVCS"
                      ? "bg-white border border-dte-500 text-dte-500"
                      : "border border-dte-500 bg-dte-500 text-white"
                  } mx-0.5 my-0.5 py-0.5 px-0.75`}
                  onClick={() => setDisp("SERVCS")}
                >
                  Advised Service/Brush Policy
                </button>
              </>
            )}

            <div className="w-full inline-flex flex-no-wrap justify-center items-center my-1">
              <button
                className="px-1 py-0.5 bg-gray-200 font-bold mr-1"
                disabled={disableConfirm(props.action)}
                onClick={() => {
                  handleSubmit();
                  closeModal();
                }}
              >
                Yes
              </button>
              <button
                className="px-1 py-0.5 bg-gray-200 font-bold ml-1"
                onClick={() => {
                  closeModal();
                }}
              >
                Abort
              </button>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default CancelCloseModal;
