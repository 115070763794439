import React from "react";
import clsx from "clsx";

const Label: React.FC<{
  label?: string;
  error?: boolean;
  required?: boolean;
}> = ({ label, error, required }) => {
  return label ? (
    <>
      <div className="flex flex-no-wrap mb-0.5">
        <label
          className={clsx("font-medium mr-0.25", error && "error-control")}
        >
          {label}
        </label>
        {required && <p className="text-red-600">*</p>}
      </div>
    </>
  ) : (
    <></>
  );
};

export const TextAreaControl: React.FC<{
  label?: string;
  value: string;
  required?: boolean;
  disabled?: boolean;
  setValue: (value: string) => void;
  error?: boolean;
}> = ({ label, value, setValue, required, disabled, error }) => {
  return (
    <div className={clsx("flex flex-col w-full", disabled && "disabled")}>
      <Label label={label} error={error} required={required} />
      <div className="flex flex-wrap w-full">
        <textarea
          disabled={disabled}
          value={value}
          className="p-0.5 h-5.5 bg-white w-full border border-dte-500"
          placeholder="Enter Notes..."
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    </div>
  );
};
