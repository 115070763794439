import { AxiosRequestConfig } from "axios";
import { DropdownOption } from "../../common/models/DropdownOption";
import http from "../Http";
import { TreeTrimActivity } from "../models/TreeTrimActivity";
import { TreeTrimOutageInfo } from "../models/TreeTrimOutageInfo";

const headers = {
  headers: {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUB_KEY,
  },
};

const config: AxiosRequestConfig = {
  baseURL: `${process.env.REACT_APP_API_URL}/taskmanagement`,
  ...headers,
};

export const getTreeTrimActivitiesForJob = async (jobId: string) => {
  const params = {
    jobId,
  };
  const res = await http.get<TreeTrimActivity[]>("/getTreeTrimActivities", {
    ...config,
    params,
  });
  return res;
};

export const getTreeTrimOutageInfoForJob = async (jobId: string) => {
  const params = {
    jobId,
  };
  const res = await http.get<TreeTrimOutageInfo[]>("/getTreeTrimOutageInfo", {
    ...config,
    params,
  });
  return res;
};

export const saveTreeTrimActivity = async (
  treeTrimActivity: TreeTrimActivity
) => {
  const res = await http.post<TreeTrimActivity>(
    "/saveTreeTrimActivity",
    treeTrimActivity,
    config
  );
  return res;
};

export const removeTreeTrimActivity = async (id: string) => {
  const res = await http.post<{ id: string }>(
    "/removeTreeTrimActivity",
    { id },
    config
  );
  return res;
};

export const saveTreeTrimActivities = async (
  treeTrimActivities: TreeTrimActivity[],
  removeTreeTrimIds: string[]
) => {
  const saveResults = await Promise.all(
    treeTrimActivities.map((a) => saveTreeTrimActivity(a))
  );

  const removeResults = await Promise.all(
    removeTreeTrimIds.map((id) => removeTreeTrimActivity(id))
  );

  return [...saveResults, ...removeResults];
};

export const saveOutageInfo = async (treeTrimActivity: TreeTrimOutageInfo) => {
  const res = await http.post<TreeTrimOutageInfo>(
    "/saveTreeTrimOutageInfo",
    treeTrimActivity,
    config
  );
  return res;
};

export const removeOutageInfo = async (id: string) => {
  const res = await http.post<{ id: string }>(
    "/removeTreeTrimOutageInfo",
    { id },
    config
  );
  return res;
};

export const saveTreeTrimOutageInfo = async (
  treeTrimActivities: TreeTrimOutageInfo[],
  removeTreeTrimIds: string[]
) => {
  const saveResults = await Promise.all(
    treeTrimActivities.map((a) => saveOutageInfo(a))
  );

  const removeResults = await Promise.all(
    removeTreeTrimIds.map((id) => removeOutageInfo(id))
  );

  return [...saveResults, ...removeResults];
};

export const getActivityTypeOptions = (): DropdownOption[] => [
  { label: "Tree Removal", value: "Tree Removal" },
  { label: "Tree Trimming", value: "Tree Trimming" },
  { label: "Brush Work", value: "Brush Work" },
  { label: "C Cut", value: "C Cut" },
  { label: "No Work", value: "No Work" },
];

export const getActivityCodeOptions = (
  activityType?: string
): DropdownOption[] => {
  if (activityType === "Tree Removal") {
    return [
      { label: "B1R", value: "B1R" },
      { label: "B1B", value: "B1B" },
      { label: "B2R", value: "B2R" },
      { label: "B2B", value: "B2B" },
      { label: "B3R", value: "B3R" },
      { label: "B3B", value: "B3B" },
      { label: "B4R", value: "B4R" },
      { label: "B4B", value: "B4B" },
      { label: "B5R", value: "B5R" },
      { label: "B5B", value: "B5B" },
    ];
  } else if (activityType === "Tree Trimming") {
    return [
      { label: "A1R", value: "A1R" },
      { label: "A1B", value: "A1B" },
      { label: "A2R", value: "A2R" },
      { label: "A2B", value: "A2B" },
      { label: "A4R", value: "A4R" },
      { label: "A4B", value: "A4B" },
      { label: "A6R", value: "A6R" },
      { label: "A6B", value: "A6B" },
    ];
  } else if (activityType === "Brush Work") {
    return [
      { label: "F1R", value: "F1R" },
      { label: "F1B", value: "F1B" },
      { label: "FR", value: "FR" },
      { label: "FB", value: "FB" },
      { label: "M1R", value: "M1R" },
      { label: "M1B", value: "M1B" },
    ];
  } else if (activityType === "C Cut") {
    return [
      { label: "C2R", value: "C2R" },
      { label: "C2B", value: "C2B" },
      { label: "C3R", value: "C3R" },
      { label: "C3B", value: "C3B" },
      { label: "C4R", value: "C4R" },
      { label: "C5R", value: "C5R" },
      { label: "C5B", value: "C5B" },
    ];
  } else if (activityType === "No Work") {
    return [{ label: "NW", value: "NW" }];
  } else {
    return [];
  }
};

export const getTreeLocationOptions = (): DropdownOption[] => {
  return [
    { label: "In ROW", value: "In ROW" },
    { label: "Out of ROW", value: "Out of ROW" },
  ];
};

export const getTreeSpeciesOptions = (): DropdownOption[] => {
  return [
    { label: "Ornamental", value: "Ornamental" },
    { label: "Ailanthus", value: "Ailanthus" },
    { label: "Apple", value: "Apple" },
    { label: "Arborvitae", value: "Arborvitae" },
    { label: "Ash", value: "Ash" },
    { label: "Austrian Pine", value: "Austrian Pine" },
    { label: "Beech", value: "Beech" },
    { label: "Bigtooth Aspen", value: "Bigtooth Aspen" },
    { label: "Birch", value: "Birch" },
    { label: "Black Locust", value: "Black Locust" },
    { label: "Black Oak", value: "Black Oak" },
    { label: "Black Walnut", value: "Black Walnut" },
    { label: "Blue Spruce", value: "Blue Spruce" },
    { label: "Box-elder", value: "Box-elder" },
    { label: "Buckthorn", value: "Buckthorn" },
    { label: "Bur Oak", value: "Bur Oak" },
    { label: "Cedar", value: "Cedar" },
    { label: "Cherry", value: "Cherry" },
    { label: "Chestnut", value: "Chestnut" },
    { label: "Cottonwood", value: "Cottonwood" },
    { label: "Crab Apple", value: "Crab Apple" },
    { label: "Dogwood", value: "Dogwood" },
    { label: "Eastern Red Cedar", value: "Eastern Red Cedar" },
    { label: "Elm", value: "Elm" },
    { label: "Hackberry", value: "Hackberry" },
    { label: "Hawthorn", value: "Hawthorn" },
    { label: "Hickory", value: "Hickory" },
    { label: "HoneyLocust", value: "HoneyLocust" },
    { label: "Linden", value: "Linden" },
    { label: "Magnolia", value: "Magnolia" },
    { label: "Maple", value: "Maple" },
    { label: "Mulberry", value: "Mulberry" },
    { label: "Northern Catalpa", value: "Northern Catalpa" },
    { label: "Norway Maple", value: "Norway Maple" },
    { label: "Oak", value: "Oak" },
    { label: "Ohio Buckeye", value: "Ohio Buckeye" },
    { label: "Osage Orange", value: "Osage Orange" },
    { label: "Paper Birch", value: "Paper Birch" },
    { label: "Pear", value: "Pear" },
    { label: "Pin Oak", value: "Pin Oak" },
    { label: "Pine", value: "Pine" },
    { label: "Poplar", value: "Poplar" },
    { label: "Quaking Aspen", value: "Quaking Aspe" },
    { label: "Red Maple", value: "Red Maple" },
    { label: "Red Oak", value: "Red Oak" },
    { label: "Red Pine", value: "Red Pine" },
    { label: "Sassafras", value: "Sassafras" },
    { label: "Silver Maple", value: "Silver Maple" },
    { label: "Spruce", value: "Spruce" },
    { label: "Sugar Maple", value: "Sugar Maple" },
    { label: "Sweetgum", value: "Sweetgum" },
    { label: "Sycamore", value: "Sycamore" },
    { label: "Tamarack", value: "Tamarack" },
    { label: "White Oak", value: "White Oak" },
    { label: "White Pine", value: "White Pine" },
    { label: "Willow", value: "Willow" },
    { label: "Yellow Poplar", value: "Yellow Poplar" },
    { label: "Brush", value: "Brush" },
    { label: "Hardwood-Other", value: "Hardwood-Other" },
    { label: "Softwood-Other", value: "Softwood-Other" },
    { label: "Vines", value: "Vines" },
  ];
};

export const getLineTypeOptions = (): DropdownOption[] => {
  return [
    { label: "Sub-Transmission", value: "Sub-Transmission" },
    { label: "Primary", value: "Primary" },
    { label: "Secondary", value: "Secondary" },
    { label: "Service", value: "Service" },
  ];
};

export const getScenarioOptions = (): DropdownOption[] => {
  return [
    { label: "Tree on Equipment", value: "Tree on Equipment" },
    {
      label: "Section of Tree on Equipment",
      value: "Section of Tree on Equipment",
    },
    { label: "Arcing/Sparking", value: "Arcing/Sparking" },
    { label: "Wire-Down", value: "Wire-Down" },
    { label: "Vines on Equipment", value: "Vines on Equipment" },
    {
      label: "Tree Contact with Line due to Wind",
      value: "Tree Contact with Line due to Wind",
    },
    { label: "Broken Limb", value: "Broken Limb" },
    {
      label: "Wire Damage due to Tree Abrasion",
      value: "Wire Damage due to Tree Abrasion",
    },
  ];
};
