import * as signalR from "@microsoft/signalr";
import { DropdownOption } from "../common/models/DropdownOption";
import { SingleComment } from "./Api";

const MapServerURL = process.env.REACT_APP_MAP_URL;

export const arcgisBaseUrl = `${process.env.REACT_APP_API_URL}/esri/services`;
export const dteVehicleLayerPath = `${arcgisBaseUrl}/CentralDispatch/Service_Ops_And_Substations/MapServer`;
export const searchLayerPath = `${arcgisBaseUrl}/CentralDispatch/Service_Ops_And_Substations/MapServer/9`;
export const treeTrimTruckLayerPath = `${arcgisBaseUrl}/CentralDispatch/Tree_Trim_Trucks/MapServer`;
export const contractCrewTruckLayerPath = `${arcgisBaseUrl}/OutageStatusApp/Contract_Crew_Trucks/MapServer/0`;
export const electricFacilityLayerPath = `${arcgisBaseUrl}/OutageStatusApp/EdisonFacility/MapServer`;
export const outageJobLayerURL =
  "http://outagemap.serv.dteenergy.com/GISRest/services/OMP/OutageLocations/MapServer/2";
export const contractCrewTruckLayerId = "Contract_Crew_Trucks";
export const dteVehicleLayerId = "DTE_Vehicles";
export const treeTrimTruckLayerId = "Tree_Trim_Trucks";
export const electricFacilitiesLayerId = "Electric_Facilities";
export const IS_SUPERVISOR = "isSupervisor";

export const CHIEF_KEY = "extension_a326dde48688470e84a7da80dceb2854_dtechief";

export type Role = {
  tag: string;
  tt: boolean;
  jwt: string;
  crews: string[];
  foreman: boolean;
  supervisor: boolean;
  id: string;
  name: string;
  serviceCenter?: string;
  overrides?: {
    tag?: "employee" | "contractor";
    tt?: boolean;
    supervisor?: boolean;
  };
};

export const buildTokenUrl = (userId: string, password: string) =>
  `${MapServerURL}/arcgis/tokens/?request=gettoken&username=${userId}&password=${encodeURIComponent(
    password
  )}`;

export const buildLayerUrl = (url: string, token: string, subKey: string) =>
  `${url}/?access_token=${encodeURIComponent(
    token
  )}&subscription-key=${encodeURIComponent(subKey)}`;

export type ServiceCenter = {
  value: string;
  label: string;
  region: string;
  description: string;
  count: number;
};

export const YES = "YES";
export const NO = "NO";

export const serviceCenters: ServiceCenter[] = [
  {
    value: "LAP",
    label: "Lapeer",
    region: "T",
    description: "Lapeer",
    count: 0,
  },
  {
    value: "MAR",
    label: "Marysville",
    region: "T",
    description: "Marysville",
    count: 0,
  },
  {
    value: "MTC",
    label: "Mt Clemens",
    region: "T",
    description: "Mt Clemens",
    count: 0,
  },
  {
    value: "NAE",
    label: "North Area",
    region: "T",
    description: "North Area",
    count: 0,
  },
  {
    value: "HWL",
    label: "Howell",
    region: "N",
    description: "Howell",
    count: 0,
  },
  {
    value: "PON",
    label: "Pontiac",
    region: "N",
    description: "Pontiac",
    count: 0,
  },
  {
    value: "SBY",
    label: "Shelby",
    region: "N",
    description: "Shelby",
    count: 0,
  },
  {
    value: "CAN",
    label: "Caniff",
    region: "D",
    description: "Caniff",
    count: 0,
  },
  {
    value: "RFD",
    label: "Redford",
    region: "D",
    description: "Redford",
    count: 0,
  },
  {
    value: "TBY",
    label: "Trombly",
    region: "D",
    description: "Trombly",
    count: 0,
  },
  {
    value: "ANN",
    label: "Ann Arbor",
    region: "W",
    description: "Ann Arbor",
    count: 0,
  },
  {
    value: "NPT",
    label: "Newport",
    region: "W",
    description: "Newport",
    count: 0,
  },
  {
    value: "WWS",
    label: "Western Wayne",
    region: "W",
    description: "Western Wayne",
    count: 0,
  },
  {
    value: "TNA",
    label: "TNA",
    region: "",
    description: "",
    count: 0,
  },
];

export const CrewAssignmentStatuses = [
  {
    value: "AK",
    label: "AK",
  },
  {
    value: "ER",
    label: "ER",
  },
  {
    value: "AR",
    label: "AR",
  },
];

export const causeCodes = [
  {
    value: "INCI",
    label: "INC ICE",
  },
  {
    value: "INCW",
    label: "INC WIND",
  },
  {
    value: "TREEIROW",
    label: "TREE IN ROW",
  },
  {
    value: "TREEBROW",
    label: "TREE OUT ROW",
  },
  {
    value: "WIND",
    label: "WIND",
  },
  {
    value: "LTNING",
    label: "LTNING",
  },
  {
    value: "ICE",
    label: "ICE",
  },
  {
    value: "EQBRK",
    label: "EQBRK",
  },
  {
    value: "LOADING",
    label: "LOADING",
  },
  {
    value: "PIOTHER",
    label: "PIOTHER",
  },
  {
    value: "PIETHEFT",
    label: "PIETHEFT",
  },
  {
    value: "INSIDE",
    label: "INSIDE",
  },
  {
    value: "OTHRUTIL",
    label: "OTHRUTIL",
  },
  {
    value: "OKARRIV",
    label: "OKARRIV",
  },
  {
    value: "ANIMAL",
    label: "ANIMAL",
  },
  {
    value: "PL INTEN",
    label: "PLAN INTEN",
  },
  {
    value: "TR INTEN",
    label: "TRBL INTEN",
  },
  {
    value: "UNKNOWN",
    label: "UNKNOWN",
  },
];

export const JobStatusOptions = [
  {
    value: "AK",
    label: "AK",
  },
  {
    value: "ER",
    label: "ER",
  },
  {
    value: "AR",
    label: "AR",
  },
  {
    value: "P",
    label: "P",
  },
];

export const cancelCloseStatusCodes = ["A", "D", "P", "V"];
export const cancelCloseTreeTrimTypes = ["TT", "TRWIRE"];
export const closeDamageAssessJobTypes = ["SDXL", "XCURR", "ONELEG", "SDLTSOK"];
export const closeNonOutageJobTypes = [
  "CKTEVAL",
  "HZ",
  "ARC",
  "PPWD",
  "NONHZD",
  "PREFLT",
];

export const FilteredCrewAssignStatus = ["AV", "AQ", "AP", "AF", "AM"];

export const FilteredJobStatus = ["X", "C", "Dup and Cancel", "Closed"];

export const DAJobTypesForEditableSubType = [
  "XCURR",
  "SDXL",
  "SDLTSOK",
  "ONELEG",
  "OHTRANS",
  "HOSP",
  "PRIMARY",
  "SCHOOL",
  "UGPSC",
  "CKTEVAL",
  "CP",
  "FUSE",
  "ISO",
  "OHCKT",
  "RECLO",
  "UGCKT",
  "UGTRANS",
];

export const DAOKOnArrival = { value: "OK", label: "OK On Arrival" };
export const DAJointUse = { value: "OK", label: "Joint Use" };

export const getJobSubTypeOptionsForDamageAssessJobs = (
  jobType: string
): { value: string; label: string }[] => {
  switch (jobType) {
    case "XCURR":
      return XCURRJobSubTyeOptions;
    case "SDXL":
      return SDXLJobSubTypeOptions;
    case "SDLTSOK":
      return SDLTSOKJobSubTypeOptions;
    case "ONELEG":
      return ONELEGJobSubTypeOptions;
    case "OHTRANS":
      return OHTransSubTypeOptions;
    case "HOSP":
      return HOSPITALOUTSubTypesOptions;
    case "PRIMARY":
      return PrimaryCustSubTypeOptions;
    case "SCHOOL":
      return SchoolCustSubTypeOptions;
    case "UGPSC":
      return UGPrimarySwitchCabinetTransSubTypeOptions;
    case "CKTEVAL":
      return CircuitEvalSubTypeOptions;
    case "CP":
      return CPSubTypeOptions;
    case "FUSE":
      return FUSESubTypeOptions;
    case "ISO":
      return ISOSubTypeOptions;
    case "OHCKT":
      return OHCKTSubTypeOptions;
    case "RECLO":
      return RECLOSubTypeOptions;
    case "UGCKT":
      return UGCKTSubTypeOptions;
    case "UGTRANS":
      return UGTRANSSubTypeOptions;
  }

  return [];
};

const XCURRJobSubTyeOptions = [
  { value: "CONF", label: "Confirmed" },
  { value: "PRED", label: "Predicted" },
];

const SDXLJobSubTypeOptions = [
  { value: "**NOTE**", label: "Note: See Special Note" },
  { value: "EXHCONF", label: "Expert - House Confirmed" },
  { value: "EXPCONF", label: "Expert - Pole Confirmed" },
  {
    value: "EXPCONF-XFMR",
    label: "Expert - Pole Confirmed With Transformer",
  },
  { value: "EXPCONF-NOXFMR", label: "Expert - Pole Confirmed No Transformer" },
  { value: "UGUNCON", label: "Underground UnConfirmed" },
  { value: "UNCON", label: "Unconfirmed" },
];

const SDLTSOKJobSubTypeOptions = [
  {
    value: "EXHCONF",
    label: "Down at the house (EXHCONF - expert house confirmed) ",
  },
  {
    value: "EXPCONF-NOXFMR",
    label:
      "Down at the pole (EXPCONF-NOXFMR – expert pole confirmed, NO trans location)",
  },
  {
    value: "EXPCONF-XFMR",
    label:
      "Down at the pole (EXPCONF-XFMR – expert pole confirmed, trans location)",
  },
  {
    value: "**NOTE**",
    label:
      "Special Circumstances, such as change to primary/sec down, customer on oxygen etc. (**NOTE**)",
  },
];

const ONELEGJobSubTypeOptions = [
  { value: "EXPCONF-NOXFMR", label: "Confirmed 1 leg dead, NO TRANS" },
  { value: "EXPCONF-XFMR", label: "Confirmed 1 leg dead, TRANS LOCATION" },
  { value: "NEUT", label: "Open Neutral found" },
];

const OHTransSubTypeOptions = [{ value: "CONF", label: "Confirmed Outage" }];

const HOSPITALOUTSubTypesOptions = [
  { value: "**NOTE**", label: "Note: See Special Note" },
  { value: "CONF", label: "Confirmed" },
  { value: "OHCONF", label: "OH Confirmed" },
  { value: "UGCONF", label: "UG Confirmed" },
];

const PrimaryCustSubTypeOptions = [
  { value: "**NOTE**", label: "Note: See Special Note" },
  { value: "CONF", label: "Confirmed" },
  { value: "OHCONF", label: "OH Confirmed" },
  { value: "UGCONF", label: "UG Confirmed" },
];

const SchoolCustSubTypeOptions = [
  { value: "**NOTE**", label: "Note: See Special Note" },
  { value: "CONF", label: "Confirmed" },
  { value: "OHCONF", label: "OH Confirmed" },
  { value: "UGCONF", label: "UG Confirmed" },
];

const UGPrimarySwitchCabinetTransSubTypeOptions = [
  { value: "CONF", label: "Confirmed Outage" },
];

const CircuitEvalSubTypeOptions = [
  { value: "EVALED", label: "Evaluated" },
  { value: "EVALED-FOLLUP", label: "Evaluated Follow UP" },
  { value: "PATRLING", label: "Patrolling" },
  { value: "UNASNED", label: "Unassigned" },
];

const CPSubTypeOptions = [
  { value: "CONF", label: "Confirmed" },
  { value: "PRED", label: "Predicted" },
];

const FUSESubTypeOptions = [
  { value: "CONF", label: "Confirmed" },
  { value: "PRED", label: "Predicted" },
];

const ISOSubTypeOptions = [
  { value: "CONF", label: "Confirmed" },
  { value: "PRED", label: "Predicted" },
];

const OHCKTSubTypeOptions = [
  { value: "CONF", label: "Confirmed" },
  { value: "PRED", label: "Predicted" },
];

const RECLOSubTypeOptions = [
  { value: "CONF", label: "Confirmed" },
  { value: "PRED", label: "Predicted" },
];

const UGCKTSubTypeOptions = [
  { value: "CONF", label: "Confirmed" },
  { value: "PRED", label: "Predicted" },
];

const UGTRANSSubTypeOptions = [
  { value: "CONF", label: "Confirmed" },
  { value: "PRED", label: "Predicted" },
];

export const TTJobSubTypeOptions = [
  {
    value: "CONF",
    label: "CONF, Ready for Trim",
  },
  {
    value: "BKYRD",
    label: "Backyard machine needed",
  },
  {
    value: "BRUSH",
    label: "Brush pickup only",
  },
  {
    value: "TT-OH",
    label: "TT needs OHL Immediate Threat",
  },
  {
    value: "**NOTE**",
    label: "NOTE, special circumstances",
  },
  {
    value: "TT-OH-FOLLUP",
    label: "TT needs OHL Non-Hazard, Safe For Follow Up",
  },
];
export const yesNoOptions = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];

export const jobStatusOptions = [
  {
    value: "P",
    label: "Pending",
  },
  {
    value: "A",
    label: "Assigned",
  },
  {
    value: "H",
    label: "Held",
  },
  {
    value: "B",
    label: "Broadcast and Cancel",
  },
  {
    value: "X",
    label: "Broadcast and Cancel",
  },
  {
    value: "C",
    label: "Cancel",
  },
  {
    value: "V",
    label: "Verified",
  },
  {
    value: "20",
    label: "Dup and Cancel",
  },
  {
    value: "R",
    label: "Agreed Response",
  },
  {
    value: "CO",
    label: "Closed",
  },
  {
    value: "W",
    label: "Awaiting Closure",
  },
  {
    value: "D",
    label: "Dispatch Assigned",
  },
  {
    value: "E",
    label: "Dependant Status",
  },
  {
    value: "U",
    label: "Scheduled",
  },
];

export type JobTypeFilter =
  | "All"
  | "Outage"
  | "TreeTrim"
  | "NonOutage"
  | "DamageAssess";

export const defualtToOneCustOutJobs = [
  "ONELEG",
  "SDXL",
  "PRIMARY",
  "EHS",
  "HOSP",
  "HIC",
  "SCHOOL",
];

export const OutageJobs = [
  "CP",
  "FUSE",
  "HOSP",
  "ISO",
  "OHCKT",
  "OHTRANS",
  "ONELEG",
  "PRIMARY",
  "RECLO",
  "SCHOOL",
  "SDXL",
  "UGCKT",
  "UGPSC",
  "UGTRANS",
  "XCURR",
  "CUTCOND",
  "EHS",
  "HIC",
  "OHDS",
  "OHSWC",
  "OPENPOINT",
  "SOX",
  "TEMPFUSE",
  "TEMPOHDS",
  "TOX",
  "UGDS",
  "UGFUSE",
  "UGSWC",
];

export const TreeTrimJobs = ["TT", "TRWIRE"];

export const NonOutageJobs = [
  "CKTEVAL",
  "ARC",
  "BP",
  "COLLECT",
  "CONTACT",
  "FLDEMER",
  "FLICKER",
  "FOLLUP",
  "GROUND",
  "HIGHVOL",
  "HZ",
  "INTERRUPTIBLE",
  "LOWVOL",
  "MISC-POLE-EQUIP",
  "MISCPF",
  "MULTIARC",
  "NONHZD",
  "OIL-EQUIPMENT",
  "OPL",
  "PLASLO",
  "PLDMETER",
  "POLE",
  "PPWD",
  "PREFLT",
  "PROPERTY-NONHZD",
  "PTFIRE",
  "REPLACE-WIRE",
  "SDLTSOK",
  "SLO",
  "STRUFIREPF",
  "TRAFCSIGPF",
  "TRAPPED",
  "TREE-TRIMMING",
  "UGEXP",
  "UNDERGROUND",
  "WDPF",
];

export const DamageAssessmentJobs = [
  "CP",
  "FUSE",
  "HOSP",
  "ISO",
  "OHCKT",
  "OHTRANS",
  "ONELEG",
  "PRIMARY",
  "RECLO",
  "SCHOOL",
  "SDXL",
  "UGCKT",
  "UGPSC",
  "UGTRANS",
  "XCURR",
  "CKTEVAL",
  "SDLTSOK",
];

export const AllJobs = [
  ...OutageJobs,
  ...TreeTrimJobs,
  ...NonOutageJobs,
  ...DamageAssessmentJobs,
];

export const JobTypeOptions: { label: string; value: JobTypeFilter }[] = [
  { label: "All Jobs", value: "All" },
  { label: "Outage Jobs", value: "Outage" },
  { label: "Tree Trim Jobs", value: "TreeTrim" },
  { label: "Non-Outage Jobs", value: "NonOutage" },
  { label: "Damage Assessment Jobs", value: "DamageAssess" },
];

const jobState = "A";

//const jobStatus = ["P", "A", "H", "B", "X", "V", "R", "W", "D", "E", "U"];

type AllServiceCenterType = "All";
export const ALL_SERVICE_CENTER: AllServiceCenterType = "All";

export type ServiceCenterSelection = ServiceCenter[] | AllServiceCenterType;

export const buildJobFilter = (
  filterType: JobTypeFilter,
  aor: ServiceCenterSelection
) => {
  if (aor === ALL_SERVICE_CENTER || aor.length === 0) {
    switch (filterType) {
      case "All":
        return { jobType: AllJobs, jobState };
      case "Outage":
        return { jobType: OutageJobs, jobState };
      case "TreeTrim":
        return { jobType: TreeTrimJobs, jobState };
      case "NonOutage":
        return { jobType: NonOutageJobs, jobState };
      case "DamageAssess":
        return { jobType: DamageAssessmentJobs, jobState };
    }
  } else {
    const aors = aor.map((x) => x.value);
    switch (filterType) {
      case "All":
        return { jobType: AllJobs, aor: aors, jobState };
      case "Outage":
        return { jobType: OutageJobs, aor: aors, jobState };
      case "TreeTrim":
        return { jobType: TreeTrimJobs, aor: aors, jobState };
      case "NonOutage":
        return { jobType: NonOutageJobs, aor: aors, jobState };
      case "DamageAssess":
        return { jobType: DamageAssessmentJobs, aor: aors, jobState };
    }
  }
};

/**
 * This chunk of code was provided and cleaned up slightly from the client as a means to translate
 * what we call a circuit id (feederid for them) into path segments to retrieve these files.
 * Combine numeric part and chr part of the code
 * Remove if the pdf name has leading 0
 * example i/p-FDR:PHENX9804, o/p-9804PHENX.pdf
 * Subtransmission Operating Map
 * SOC One Line Diagrams
 * Subtransmission Operating Map
 * Subtransmission Operating Map
 * SOC One Line Diagrams
 * @param feederID
 * @returns
 */
export const oneLinesPDFUrl = (feederID: string): string => {
  const pdfPathSolutions = [];

  // check if FEEDERID starts with PSW
  const regexPSW = /^PSW\d+/g;

  if (regexPSW.test(feederID)) {
    pdfPathSolutions.push(`Drawing-Primary Services Schematics/${feederID}`);
  }

  // check FEEDERID TIE & TRK
  // Example TIE 1224A-B Expected TIE1224
  // FDR:TRK 1135A-S Expected TRK1135
  const regexTRKTIE = /^(TRK|TIE) \d+/g;

  if (regexTRKTIE.test(feederID)) {
    pdfPathSolutions.push(
      `Drawing-Subtransmission Operating Map/${feederID
        .match(regexTRKTIE)
        ?.join("")
        .replace(/ /g, "")}`
    );
  }

  // check if FEEDERID has WP (Wind Park) follows below pattern
  const regexWP = /[A-Z]{8}\d+/;
  const regexWP2 = /WPWTG/;

  if (regexWP.test(feederID) && regexWP2.test(feederID)) {
    pdfPathSolutions.push(
      `Drawing-SOC One Line Diagrams/${feederID.replace("WPWTG", "WP WTG")}`
    );
  }

  // check if FEEDERID has SP (Solar Park) follows below pattern
  const regexSP = /[A-Z]{7}\d+/;
  const regexSP2 = /SPSU/;

  if (regexSP.test(feederID) && regexSP2.test(feederID)) {
    pdfPathSolutions.push(
      `Drawing-SOC One Line Diagrams/${feederID.replace("SPSU", "SP SU")}`
    );
  }

  // Distrebution Feeders
  // [A-Z ]{5}\d{4}
  const regexDistrebution = /^[A-Z ]{5}\d{4}/g;

  if (regexDistrebution.test(feederID)) {
    pdfPathSolutions.push(
      `Drawing-DC Operating Map/${feederID
        .match(/[0-9]/g)
        ?.join("")
        .replace(/^0+/, "")}${feederID.match(/[a-zA-Z]/g)?.join("")}`
    );
  }

  if (pdfPathSolutions.length === 0) {
    return feederID;
  } else if (pdfPathSolutions.length === 1) {
    return encodeURI(`${pdfPathSolutions.join("")}.pdf`);
  } else if (pdfPathSolutions.length > 1) {
    console.warn(`${feederID} Failed Regex. Multiple matches found !! `);

    console.log(pdfPathSolutions);

    // this is supposed to be a failure case, the old code we brought over used to just return the id at this point.
    // im just using empty string to indicate failure for now.
    return "";
  }

  return "";
};

export const dateFormat: any = [
  "en-US",
  { dateStyle: "short", timeStyle: "short" },
];

export const isValidDate = (date: string) => {
  const parsed = Date.parse(date);
  if (isNaN(parsed) || parsed === null || parsed === undefined) {
    return false;
  } else {
    return true;
  }
};

export const availableCrewStatuses = ["AV", "AQ", "AP", "AF", "AM", "EO", "1R"];

const crewStatusLabels = [
  {
    label: "Available",
    value: "AV",
  },
  {
    label: "AvInQuarters",
    value: "AQ",
  },
  {
    label: "AvOnPager",
    value: "AP",
  },
  {
    label: "AvOnFoot",
    value: "AF",
  },
  {
    label: "AvOnMdt",
    value: "AM",
  },
  {
    label: "AvEmergOnly",
    value: "EO",
  },
  {
    label: "AvFstRspndr",
    value: "1R",
  },
  {
    label: "Dispatched",
    value: "DP",
  },
  {
    label: "Enroute",
    value: "ER",
  },
  {
    label: "Arrive",
    value: "AR",
  },
  {
    label: "ArriveDanger",
    value: "AD",
  },
  {
    label: "LongArrive",
    value: "LA",
  },
  {
    label: "Service",
    value: "OS",
  },
  {
    label: "Transport",
    value: "TR",
  },
  {
    label: "TransportArrive",
    value: "TA",
  },
  {
    label: "Acknowledge",
    value: "AK",
  },
  {
    label: "GroupMember",
    value: "GP",
  },
  {
    label: "PendingAV",
    value: "PA",
  },
  {
    label: "PendingMobile",
    value: "PM",
  },
  {
    label: "ReliefReq",
    value: "RL",
  },
  {
    label: "OnePSNeeded",
    value: "P1",
  },
  {
    label: "TwoPSNeeded",
    value: "P2",
  },
  {
    label: "FieldSup",
    value: "FS",
  },
  {
    label: "HelpRequested",
    value: "HR",
  },
  {
    label: "Relief Sent",
    value: "RS",
  },
  {
    label: "Dispatch Assigned",
    value: "DA",
  },
];

export const crewStatusValueToLabel = (status: string) => {
  const label = crewStatusLabels.find((x) => x.value === status)?.label;
  if (label) {
    return label;
  } else {
    return status;
  }
};

export const sortCommentsByDate = (a: SingleComment, b: SingleComment) => {
  if (isValidDate(a.timestamp) && isValidDate(b.timestamp)) {
    return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
  } else if (isValidDate(a.timestamp)) {
    return -1;
  } else if (isValidDate(b.timestamp)) {
    return 1;
  } else {
    return 1;
  }
};

export const getFromLocalStorageOrDefault = <T>(
  key: string,
  defaultValue: T,
  validValues?: T[]
): T => {
  const value = localStorage.getItem(key);
  if (value) {
    try {
      const parsedValue = JSON.parse(value);
      if (validValues) {
        if (validValues?.includes(parsedValue)) {
          return parsedValue as T;
        } else {
          return defaultValue;
        }
      } else {
        return parsedValue as T;
      }
    } catch {
      return defaultValue;
    }
  } else {
    return defaultValue;
  }
};

export const pushToLocalStorageList = <T>(key: string, item: T) => {
  const list: T[] = getFromLocalStorageOrDefault(key, []);
  list.push(item);
  localStorage.setItem(key, JSON.stringify(list));
};

export const removeByIdFromLocalStorageList = (
  key: string,
  id: string | number
) => {
  const list: { id: string | number }[] = getFromLocalStorageOrDefault(key, []);
  const newList = list.filter((x) => x.id !== id);
  localStorage.setItem(key, JSON.stringify(newList));
};

export const genId = () => new Date().getTime().toString(36);

export const getDefaultSignalROptions = (accessToken: string) => {
  const transport = signalR.HttpTransportType.WebSockets;
  const options = {
    transport,
    logMessageContent: false,
    logger: signalR.LogLevel.Error,
    accessTokenFactory: () => accessToken,
  };

  return options;
};
