import clsx from "clsx";

const TreeTrimFormRow: React.FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <div className={clsx("flex flex-wrap w-full mb-1", className)}>
    {children}
  </div>
);

export default TreeTrimFormRow;
