import React from "react";
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  JobDetailShort,
  updateJob,
  JobUpdateReq,
  JobDetail,
} from "../../lib/Api";
import { useAppSelector, useAppDispatch } from "../../lib/hooks";
import { setLoading } from "../../features/jobSlice";

const dateFormat: any = ["en-US", { dateStyle: "short", timeStyle: "short" }];

const MS_HOURS = 3600000 / 2;

const isValidDate = (date: string) => {
  const parsed = Date.parse(date);
  if (isNaN(parsed) || parsed === null || parsed === undefined) {
    return false;
  } else {
    return true;
  }
};

const ERTModal = (props: {
  job: JobDetail | JobDetailShort;
  afterSubmit: Function;
}) => {
  const [selectedDate, setSelectedDate] = React.useState<string>(
    isValidDate(props.job.ert)
      ? new Date(props.job.ert).toLocaleString(...dateFormat)
      : new Date().toLocaleString()
  );
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role.value);

  React.useEffect(() => {
    setSelectedDate(
      isValidDate(props.job.ert)
        ? new Date(props.job.ert).toLocaleString(...dateFormat)
        : new Date().toLocaleString()
    );
  }, [props.job.ert]);

  const handleSubmit = async () => {
    const req: JobUpdateReq = {
      msgId: props.job.jobId,
      ert: {
        ert: isValidDate(selectedDate)
          ? new Date(selectedDate).toISOString()
          : null,
        comments: `${role?.name ?? ""} updated ERT to ${new Date(
          selectedDate
        ).toLocaleString(...dateFormat)}`,
      },
    };
    dispatch(setLoading(true));
    const res = await updateJob(props.job.jobId, req);
    if (res.tag === "ok") {
      await props.afterSubmit();
    } else {
      console.log(res.err);
      alert(
        "Error updating job, check your internet connection. You can resend the request through the Drafts page"
      );
      dispatch(setLoading(false));
    }
  };

  const timeDiff =
    new Date(isValidDate(selectedDate) ? selectedDate : Date.now()).getTime() -
    Date.now();

  const inPast = timeDiff < 0;

  const highlight =
    inPast || !props.job.ert
      ? "text-red-600"
      : timeDiff < MS_HOURS
      ? "text-yellow-600"
      : "text-green-600";

  return (
    <>
      <Popup
        closeOnDocumentClick={true}
        modal={true}
        overlayStyle={{ cursor: "default" }}
        contentStyle={{ cursor: "default" }}
        className="responsive-modal"
        onClose={() =>
          setSelectedDate(
            isValidDate(props.job.ert)
              ? new Date(props.job.ert).toLocaleString(...dateFormat)
              : new Date().toLocaleString()
          )
        }
        onOpen={() =>
          setSelectedDate(
            isValidDate(props.job.ert)
              ? new Date(props.job.ert).toLocaleString(...dateFormat)
              : new Date().toLocaleString()
          )
        }
        trigger={
          <div
            className={`inline-flex flex-no-wrap whitespace-no-wrap items-center justify-center w-full cursor-pointer ${highlight}`}
          >
            {isValidDate(props.job.ert)
              ? new Date(props.job.ert).toLocaleString(...dateFormat)
              : "No ERT"}
            <FontAwesomeIcon className="ml-1" icon={faEdit} />
          </div>
        }
      >
        {(close) => (
          <div className="text-black py-1">
            <p className="mb-1">Select the Estimated Repair Time</p>
            <DatePicker
              selected={
                isNaN(Date.parse(selectedDate))
                  ? new Date()
                  : new Date(selectedDate)
              }
              showTimeSelect={true}
              timeFormat="hh:mm a"
              timeIntervals={15}
              onChange={(d: Date) =>
                setSelectedDate(d.toLocaleString(...dateFormat))
              }
              inline
            />
            <p className="text-lg font-bold py-0.5">
              {isValidDate(selectedDate)
                ? new Date(selectedDate).toLocaleString(...dateFormat)
                : "No ERT"}
            </p>
            <div className="w-full inline-flex flex-no-wrap justify-center items-center">
              <button
                className="px-1 py-0.5 bg-gray-200 font-bold mr-1"
                onClick={() => {
                  handleSubmit();
                  close();
                }}
              >
                Submit
              </button>
              <button
                className="px-1 py-0.5 bg-gray-200 font-bold ml-1"
                onClick={() => {
                  setSelectedDate(
                    isValidDate(props.job.ert)
                      ? new Date(props.job.ert).toLocaleString(...dateFormat)
                      : new Date().toLocaleString()
                  );
                  close();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default ERTModal;
