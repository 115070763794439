import { DropdownOption } from "../../common/models/DropdownOption";
import {
  getTreeLocationOptions,
  getLineTypeOptions,
  getTreeSpeciesOptions,
  getScenarioOptions,
} from "../../lib/api/treeTrimApi";
import {
  TreeTrimOutageInfo,
  TreeTrimOutageInfoKey,
} from "../../lib/models/TreeTrimOutageInfo";
import { yesNoOptions } from "../../lib/Utility";
import { DropdownControl } from "../controls/DropdownControl";
import { TextAreaControl } from "../controls/TextAreaControl";
import TreeTrimFormColumn from "./TreeTrimFormColumn";
import TreeTrimFormRow from "./TreeTrimFormRow";

const TreeTrimOutageInfoForm: React.FC<{
  treeTrimOutageInfo: TreeTrimOutageInfo;
  onUpdate: (value: TreeTrimOutageInfo) => void;
  errors: TreeTrimOutageInfoKey[];
}> = ({ treeTrimOutageInfo, onUpdate, errors }) => {
  const fieldHasError = (field: string) => {
    return errors?.find((e) => e === field) !== undefined;
  };

  const getOption = (
    options: () => DropdownOption[],
    selectedOption: string
  ): DropdownOption | null => {
    return options().find((o) => o.value === selectedOption) || null;
  };

  const setYesNoOption = (value: boolean | undefined) => {
    if (value === undefined) {
      return "";
    }
    return value ? "yes" : "no";
  };

  return (
    <>
      <TreeTrimFormRow>
        <TreeTrimFormColumn className="pr-0.5">
          <DropdownControl
            label="Tree Location"
            required={true}
            error={fieldHasError("treeLocation")}
            value={getOption(
              getTreeLocationOptions,
              treeTrimOutageInfo.treeLocation
            )}
            options={getTreeLocationOptions()}
            setValue={(value) => {
              onUpdate({
                ...treeTrimOutageInfo,
                treeLocation: value,
              });
            }}
          />
        </TreeTrimFormColumn>
        <TreeTrimFormColumn className="pl-0.5">
          <DropdownControl
            label="Line Type"
            required={true}
            error={fieldHasError("lineType")}
            value={getOption(getLineTypeOptions, treeTrimOutageInfo.lineType)}
            options={getLineTypeOptions()}
            setValue={(value) => {
              onUpdate({
                ...treeTrimOutageInfo,
                lineType: value,
              });
            }}
          />
        </TreeTrimFormColumn>
      </TreeTrimFormRow>
      <TreeTrimFormRow>
        <DropdownControl
          label="Tree Species"
          required={true}
          error={fieldHasError("treeSpecies")}
          value={getOption(
            getTreeSpeciesOptions,
            treeTrimOutageInfo.treeSpecies
          )}
          options={getTreeSpeciesOptions()}
          setValue={(value) => {
            onUpdate({
              ...treeTrimOutageInfo,
              treeSpecies: value,
            });
          }}
        />
      </TreeTrimFormRow>
      <TreeTrimFormRow>
        <DropdownControl
          label="Scenario"
          required={true}
          error={fieldHasError("scenario")}
          value={getOption(getScenarioOptions, treeTrimOutageInfo.scenario)}
          options={getScenarioOptions()}
          setValue={(value) => {
            onUpdate({
              ...treeTrimOutageInfo,
              scenario: value,
            });
          }}
        />
      </TreeTrimFormRow>
      <TreeTrimFormRow>
        <DropdownControl
          label="Did Tree Meet Maintenance Spec Pre-Outage"
          error={fieldHasError("maintenanceSpecMet")}
          value={getOption(
            () => yesNoOptions,
            setYesNoOption(treeTrimOutageInfo.maintenanceSpecMet)
          )}
          required={true}
          options={yesNoOptions}
          setValue={(value) => {
            onUpdate({
              ...treeTrimOutageInfo,
              maintenanceSpecMet: value === "yes" ? true : false,
            });
          }}
        />
      </TreeTrimFormRow>
      <TreeTrimFormRow>
        <DropdownControl
          label="Would Maintenance Trimming have Prevented the Outage?"
          error={fieldHasError("maintenancePreventOutage")}
          value={getOption(
            () => yesNoOptions,
            setYesNoOption(treeTrimOutageInfo.maintenancePreventOutage)
          )}
          options={yesNoOptions}
          disabled={!treeTrimOutageInfo.maintenanceSpecMet}
          setValue={(value) => {
            onUpdate({
              ...treeTrimOutageInfo,
              maintenancePreventOutage: value === "yes" ? true : false,
            });
          }}
        />
      </TreeTrimFormRow>
      <TreeTrimFormRow>
        <TextAreaControl
          label="Notes"
          value={treeTrimOutageInfo.notes}
          error={fieldHasError("notes")}
          setValue={(value) => {
            onUpdate({ ...treeTrimOutageInfo, notes: value });
          }}
        />
      </TreeTrimFormRow>
    </>
  );
};

export default TreeTrimOutageInfoForm;
