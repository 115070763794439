import React, { useState } from "react";
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { JobDetail, JobUpdateReq, updateJob } from "../../lib/Api";
import * as Util from "../../lib/Utility";
import { useAppSelector, useAppDispatch } from "../../lib/hooks";
import { setLoading } from "../../features/jobSlice";
import TreeTrimConfirmQuestions from "../TreeTrim/TreeTrimConfirmQuestions";
import {
  TreeTrimConfirmQuestionsModel,
  validateTreeTrimConfirmQuestions,
} from "../../lib/models/TreeTrimConfirmQuestionsModel";

const buildComments = (
  role: Util.Role | null,
  selectedJobSubType: string,
  label: string,
  comment: string
) => {
  let s = `${
    role?.name ?? ""
  } updated job sub type to ${selectedJobSubType} - ${label}`;

  if (comment.length > 0) {
    s = `${s}, with comment: ${comment}`;
  }

  return s;
};

type JobSubTypeOption = { label: string; value: string };

const defaultQuestionErrorMessages = {
  activityErrorMessage:
    "A tree trim activity form must be submitted before the job sub type can be changed.",
  outageErrorMessage:
    "An outage information form must be submitted before the job sub type can be changed.",
};

const JobSubTypeModal = (props: {
  job: JobDetail;
  afterSubmit: Function;
  jobSubTypeOptions: JobSubTypeOption[];
  showTextArea: boolean;
}) => {
  const [selectedJobSubType, setSelectedJobSubType] = useState(
    props.job.jobSubType ?? null
  );
  const [confirmVisible, setConfirmVisible] = useState(false);
  const dispatch = useAppDispatch();
  const activities = useAppSelector((state) => state.treeTrim.activities);
  const outageInfo = useAppSelector((state) => state.treeTrim.outageInfo);
  const role = useAppSelector((state) => state.role.value);
  const [comment, setComment] = React.useState("");
  const [errors, setErrors] = React.useState<string[]>([]);
  const [submitClicked, setSubmitClicked] = React.useState<boolean>(false);

  const defaultTreeTrimQuestions: TreeTrimConfirmQuestionsModel = {
    treeTrimNeeded: null,
    treeRelatedOutage: null,
  };
  const [treeTrimQuestions, setTreeTrimQuestions] =
    React.useState<TreeTrimConfirmQuestionsModel>(defaultTreeTrimQuestions);

  const isTreeTrimJob = () => {
    return props.job.jobType === "TT";
  };

  const validate = () => {
    if (!isTreeTrimJob()) return true;

    let isValidated = false;

    setErrors([]);
    const questionErrors = validateTreeTrimConfirmQuestions(
      treeTrimQuestions,
      activities.length > 0,
      outageInfo.length > 0
    );
    setErrors(questionErrors);

    isValidated = questionErrors.length === 0;

    return isValidated;
  };

  const handleSubmit = async (close: () => void) => {
    setSubmitClicked(true);

    const label =
      props.jobSubTypeOptions.find((x) => x.value === selectedJobSubType)
        ?.label ?? "";
    const req: JobUpdateReq = {
      msgId: props.job.jobId,
      jobSubType: {
        jobSubType: selectedJobSubType,
        jobType: props.job.jobType,
        comments: buildComments(role, selectedJobSubType, label, comment),
      },
    };
    dispatch(setLoading(true));

    if (selectedJobSubType !== "" && validate()) {
      const res = await updateJob(props.job.jobId, req);
      if (res.tag === "ok") {
        closeWrapper(close);
        await props.afterSubmit();
        setSubmitClicked(false);
      } else {
        alert(
          "Error updating job, check your internet connection. You can resend the request through the Drafts page"
        );
      }
    }
    dispatch(setLoading(false));
  };

  const closeWrapper = (close: () => void) => {
    setSelectedJobSubType(props.job.jobSubType);
    setTreeTrimQuestions(defaultTreeTrimQuestions);
    setConfirmVisible(false);
    setErrors([]);
    close();
  };

  React.useEffect(() => {
    if (selectedJobSubType !== "TT-OH") {
      setConfirmVisible(false);
    }
  }, [selectedJobSubType]);

  React.useEffect(() => {
    if (submitClicked) {
      validate();
    }
  }, [treeTrimQuestions]);

  return (
    <>
      <Popup
        closeOnDocumentClick={true}
        modal={true}
        overlayStyle={{ cursor: "default" }}
        contentStyle={{ cursor: "default" }}
        className="responsive-modal"
        onOpen={() => setSelectedJobSubType(props.job.jobSubType)}
        trigger={
          <button className="inline-flex flex-no-wrap whitespace-no-wrap items-center">
            {props.job.jobSubType === ""
              ? "??"
              : props.jobSubTypeOptions.find(
                  (x) => x.value === props.job.jobSubType
                )?.label ?? props.job.jobSubType}
            <FontAwesomeIcon className="ml-1" icon={faEdit} />
          </button>
        }
      >
        {(close) => (
          <div className="text-black py-1">
            <p className="mb-1">Select the Job Sub Type</p>
            <div className="flex flex-col">
              {props.jobSubTypeOptions.map((x) => (
                <button
                  className={`${
                    selectedJobSubType === x.value
                      ? "bg-white border border-dte-500 text-dte-500"
                      : "border border-dte-500 bg-dte-500 text-white"
                  } mx-0.5 my-0.5 py-0.5 px-0.75`}
                  onClick={() => setSelectedJobSubType(x.value)}
                  key={x.value}
                >
                  {x.label}
                </button>
              ))}
              {isTreeTrimJob() ? (
                <TreeTrimConfirmQuestions
                  errors={errors}
                  errorMessages={defaultQuestionErrorMessages}
                  onChange={(value) => {
                    setTreeTrimQuestions(value);
                  }}
                />
              ) : (
                <></>
              )}
              {props.showTextArea ? (
                <textarea
                  value={comment}
                  onChange={(e) => {
                    setComment(e.currentTarget.value);
                  }}
                  placeholder="Enter notes..."
                  className="border border-dte-500 mx-0.5 h-6 my-0.5 py-0.2 px-0.75"
                />
              ) : (
                <></>
              )}
            </div>
            {confirmVisible ? (
              <div>
                <p className="text-red-600">
                  CAUTION: <br />
                  If there's an immediate public safety hazard, contact Electric
                  Dispatch to report it. Are you sure you want to continue?
                </p>
              </div>
            ) : null}
            <div className="w-full inline-flex flex-no-wrap justify-center items-center my-1">
              {!confirmVisible ? (
                <>
                  <button
                    className="px-1 py-0.5 bg-gray-200 font-bold mr-1 disabled:text-gray-500"
                    disabled={errors.length > 0}
                    onClick={() => {
                      if (selectedJobSubType === "TT-OH") {
                        setConfirmVisible(true);
                      } else {
                        handleSubmit(close);
                      }
                    }}
                  >
                    Submit
                  </button>
                  <button
                    className="px-1 py-0.5 bg-gray-200 font-bold ml-1"
                    onClick={() => {
                      closeWrapper(close);
                    }}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="px-1 py-0.5 bg-gray-200 font-bold mr-1 disabled:text-gray-500"
                    disabled={errors.length > 0}
                    onClick={() => {
                      handleSubmit(close);
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="px-1 py-0.5 bg-gray-200 font-bold mr-1"
                    onClick={() => {
                      setSelectedJobSubType(props.job.jobSubType ?? null);
                      setConfirmVisible(false);
                    }}
                  >
                    No
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default JobSubTypeModal;
