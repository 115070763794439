import React from "react";
import { TreeTrimConfirmQuestionsModel } from "../../lib/models/TreeTrimConfirmQuestionsModel";
import { RadioControl } from "../controls/RadioControl";
import WarningBox from "../WarningBox";

const TreeTrimConfirmQuestions: React.FC<{
  errors: string[];
  errorMessages: { activityErrorMessage: string; outageErrorMessage: string };
  onChange: (value: TreeTrimConfirmQuestionsModel) => void;
}> = ({ errors, errorMessages: defaultErrorMessages, onChange }) => {
  const [treeTrimConfirmQuestions, setTreeTrimConfirmQuestions] =
    React.useState<TreeTrimConfirmQuestionsModel>({
      treeRelatedOutage: null,
      treeTrimNeeded: null,
    });

  const hasError = (field: string) => {
    return errors.indexOf(field) !== -1;
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col w-3/4">
        <div className="my-1">
          <RadioControl
            label="Is Tree Trim Needed?"
            name="treeTrimNeeded"
            error={hasError("treeTrimNeeded")}
            value={treeTrimConfirmQuestions.treeTrimNeeded ? "Yes" : "No"}
            options={["Yes", "No"]}
            required={true}
            disabled={false}
            setValue={(value) => {
              const boolValue = value === "Yes" ? true : false;
              const questions = {
                ...treeTrimConfirmQuestions,
                treeTrimNeeded: boolValue,
              };
              setTreeTrimConfirmQuestions(questions);
              onChange(questions);
            }}
          />
        </div>
        <div className="">
          {hasError("treeTrimNeeded") ? (
            <WarningBox message={defaultErrorMessages.activityErrorMessage} />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="flex flex-col w-3/4">
        <div className="my-1">
          <RadioControl
            label="Is There a Tree Related Outage?"
            name="treeRelatedOutage"
            error={hasError("treeRelatedOutage")}
            value={treeTrimConfirmQuestions.treeRelatedOutage ? "Yes" : "No"}
            options={["Yes", "No"]}
            required={true}
            disabled={false}
            setValue={(value) => {
              const boolValue = value === "Yes" ? true : false;
              const questions = {
                ...treeTrimConfirmQuestions,
                treeRelatedOutage: boolValue,
              };
              setTreeTrimConfirmQuestions(questions);
              onChange(questions);
            }}
          />
        </div>
        <div className="">
          {hasError("treeRelatedOutage") ? (
            <WarningBox message={defaultErrorMessages.outageErrorMessage} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default TreeTrimConfirmQuestions;
