import http from "./Http";
import Axios, { AxiosRequestConfig } from "axios";
import msalInstance, { renewRequest } from "../auth/authProvider";
import * as Util from "./Utility";

Axios.interceptors.request.use(async (req) => {
  try {
    const tokenRes = await msalInstance.acquireTokenSilent(renewRequest);
    req.headers.authorization = `Bearer ${tokenRes.idToken.rawIdToken}`;
  } catch (err) {
    const loginRes = await msalInstance.acquireTokenPopup(renewRequest);
    req.headers.authorization = `Bearer ${loginRes.idToken.rawIdToken}`;
  } finally {
    return req;
  }
});

const headers = {
  headers: {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUB_KEY,
  },
};

const config: AxiosRequestConfig = {
  baseURL: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ADMS_ROUTE}`,
  ...headers,
};

export interface CosmosCrewMember {
  _id: string;
  id: string;
  name: string;
  email: string;
  smsReceivers: SMSReceivers;
  org: string;
  crew: string[];
  serviceCenter: any[];
  options: UserOptions;
  auth: UserAuth;
  active: boolean;
  createdBy: UserCreatedBy;
  createdAt: number;
}

export interface UserAuth {
  MSSTS: Mssts;
}

export interface Mssts {
  localAccountId: null;
  name: null;
  username: null;
}

export interface UserCreatedBy {
  id: string;
  name: string;
  org: string;
  email: string;
}

export interface UserOptions {
  tag: string;
  supervisor: boolean;
  treeTrim: boolean;
  damageAssessment: boolean;
}

export interface SMSReceivers {
  countryCode: string;
  phoneNumber: null;
}

export const getUserDetails = async (id: string) => {
  const res = await http.get<CosmosCrewMember | null>(
    `${process.env.REACT_APP_ADMIN_URL}/api/crewMemberGet?code=${process.env.REACT_APP_ADMIN_API_KEY}`,
    {
      params: {
        id: id.toUpperCase(),
      },
      ...headers,
    }
  );
  return res;
};

export const uploadImage = async (image: File) => {
  const data = new FormData();
  data.append("file", image);
  const res = await http.post<{ message: string }>(
    `${process.env.REACT_APP_API_URL ?? ""}/images/upload`,
    data,
    {
      ...headers,
    }
  );
  return res;
};

export const getEmployeeDetail = async (id: string) => {
  const url = `/CrewMember/${encodeURIComponent(id)}`;
  const res = await http.get<GetEmployeeDetailRes>(url, config);
  return res;
};

export type GetEmployeeDetailRes = {
  employeeId: string;
  crewId: string;
  phoneNumber: string;
  name: string;
  crewStatus: string;
};

export const crewLogOn = async (crew: string) => {
  const url = `/Crew/${encodeURIComponent(crew)}/Logon`;
  const res = await http.patch(url, {}, config);
  return res;
};

export const crewLogOff = async (crew: string) => {
  const url = `/Crew/${encodeURIComponent(crew)}/Logoff`;
  const res = await http.patch(url, {}, config);
  return res;
};

export type CrewStatusByCrewListSuccess = {
  jobId: string;
  crewId: string;
  crewAssignmentStatus: string;
  crewType: string;
  LogonStatus: "Y" | "N";
};

export type CrewStatusByCrewListError = {
  crewId: string;
  errorCode: string;
  errorDescription: string;
};

export type CrewStatusByCrewList =
  | CrewStatusByCrewListSuccess
  | CrewStatusByCrewListError;

export const getCrewStatusByCrewList = async (crewIds: string[]) => {
  const url = `/Status`;
  const params = {
    crewIds: crewIds.join(),
  };
  const res = await http.get<CrewStatusByCrewList[]>(url, {
    ...config,
    params,
  });
  return res;
};

export type CrewStatusUpdateReq = {
  msgId: string;
  jobId: string;
  action: string;
};

export const updateCrewStatus = async (
  crew: string,
  update: CrewStatusUpdateReq
) => {
  const url = `/Crew/${encodeURIComponent(crew)}/Status`;
  const res = await http.patch(url, update, config);
  return res;
};

export type CrewAssignmentStatusUpdateReq = {
  msgId: string;
  jobId: string;
  comments: string;
  crewAssignmentStatus: string;
  disposition?: string;
};

export const updateCrewAssignmentStatus = async (
  crew: string,
  update: CrewAssignmentStatusUpdateReq
) => {
  const url = `/Crew/${encodeURIComponent(crew)}/CrewAssignment/Status`;
  const res = await http.patch(url, update, config);
  return res;
};

type JobDetailCrewAssignment = {
  crewId: string;
  crewAssignmentStatus: string;
};

export type JobDetail = {
  jobId: string;
  callSource: string;
  jobType: string;
  jobSubType: string;
  causeCode?: string;
  aor: string;
  jobStatus: string;
  circuit: string;
  ert: string;
  premise: string;
  customerOut: string | number;
  region: string;
  outageDate: string;
  crewAssignmentStatus: string;
  crewId: string;
  crew: JobDetailCrewAssignment[];
  longitude: string;
  latitude: string;
  address: string;
  phoneNumber: string;
  name: string;
  numOfCalls: string;
  prn_flag: string;
  xCord: string;
  yCord: string;
  meterId: string;
  comments: Comments;
};

export type Comments =
  | { comments: SingleComment[] | SingleComment }
  | SingleComment;

export type SingleComment = {
  timestamp: string;
  comment: string;
};

export const getJobDetailById = async (job: string) => {
  const url = `/Job/${encodeURIComponent(job)}`;
  const res = await http.get<JobDetail>(url, config);
  if (res.tag === "ok") {
    if (res.data.customerOut !== "") {
      res.data.customerOut = Number(res.data.customerOut);
    }
    if (
      Util.defualtToOneCustOutJobs.includes(res.data.jobType) &&
      !(Number(res.data.customerOut) > 0)
    ) {
      res.data.customerOut = 1;
    }
  }
  return res;
};

export const getCircuitMap = async (pathSegments: string) => {
  const url = `${process.env.REACT_APP_API_URL}/circuitmap/${pathSegments}`;
  return await http.get<Blob>(url, {
    ...headers,
    responseType: "blob",
  });
};

export interface JobListByCrewAssignment {
  jobList: JobCrewAssignment[] | JobCrewAssignment;
}

export interface JobCrewAssignment {
  jobId: string;
  crewAssignments: CrewAssignment[] | CrewAssignment;
}

export interface CrewAssignment {
  crewId: string;
  name: string;
  crewAssignmentStatus: string;
  eta: string;
}

export const getCrewAssignmentByJobId = async (jobs: string[]) => {
  const params = { jobIds: jobs.join(",") };
  const url = `/Job/CrewAssignment`;
  const res = await http.get<{ job: JobListByCrewAssignment }>(url, {
    ...config,
    params,
  });
  return res;
};

export const getJobDetailByCrewList = async (crews: string[]) => {
  const params = { crewIds: crews.join(",") };
  const url = `/Crew/Job`;
  const res = await http.get<JobListByCrew>(url, {
    ...config,
    params,
  });
  if (res.tag === "ok") {
    if (Array.isArray(res.data.job.jobs)) {
      res.data.job.jobs.forEach((x) => {
        if (!("errorCode" in x)) {
          if (x.customerOut !== "") {
            x.customerOut = Number(x.customerOut);
          }
          if (
            Util.defualtToOneCustOutJobs.includes(x.jobType) &&
            !(Number(x.customerOut) > 0)
          ) {
            x.customerOut = 1;
          }
        }
      });
    } else {
      if (!("errorCode" in res.data.job.jobs)) {
        if (res.data.job.jobs.customerOut !== "") {
          if (res.data.job.jobs.customerOut !== "") {
            res.data.job.jobs.customerOut = Number(
              res.data.job.jobs.customerOut
            );
          }
          if (
            Util.defualtToOneCustOutJobs.includes(res.data.job.jobs.jobType) &&
            !(Number(res.data.job.jobs.customerOut) > 0)
          ) {
            res.data.job.jobs.customerOut = 1;
          }
        }
      }
    }
  }

  return res;
};

export const convertJobByCrewToJobDetailShort = (
  job: JobByCrew
): JobDetailShort => {
  const converted: JobDetailShort = {
    jobId: job.jobId,
    jobType: job.jobType,
    jobSubType: job.jobSubType,
    outageDate: job.outageDate,
    callsource: job.callSource,
    causeCode: job.causeCode,
    ert: job.etr,
    circuit: job.circuit,
    customerOut: job.customerOut,
    address: job.address,
    longitude: job.longitude,
    latitude: job.latitude,
    aor: job.aor ?? "",
    crewAssignmentStatus: job.crewAssignmentStatus,
    jobStatus: job.jobStatus,
    crewId: job.crewId,
  };
  return converted;
};

export type JobByCrewErr = {
  errorCode: string;
  errorDescription: string;
  crewId: string;
};

export type JobListByCrew = {
  job: { jobs: Array<JobByCrew | JobByCrewErr> | JobByCrew | JobByCrewErr };
};

export type JobByCrew = {
  jobId: string;
  jobType: string;
  jobSubType: string;
  outageDate: string;
  callSource: string;
  causeCode: string;
  etr: string;
  circuit: string;
  customerOut: string | number;
  address: string;
  longitude: string;
  latitude: string;
  crewId: string;
  aor: string;
  jobStatus: string;
  crewAssignmentStatus: string;
};

export type JobFilter = {
  jobType?: string[];
  jobSubType?: string;
  jobStatus?: string | string[];
  jobState?: string;
  aor?: string[];
  startDate?: string;
  endDate?: string;
  crewId?: string;
  dateRange?: string[];
  excludeFollowUpJobs?: "y" | "n";
  excludePldJobs?: "y" | "n";
};

export type JobDetailShort = {
  jobId: string;
  jobType: string;
  jobSubType: string;
  outageDate: string;
  callsource?: string;
  causeCode: string;
  ert: string;
  circuit: string;
  customerOut: string | number;
  address: string;
  longitude: string;
  latitude: string;
  aor: string;
  crewId: string;
  jobStatus: string;
  crewAssignmentStatus: string;
};

export const getJobDetailByFilter = async (filter: JobFilter) => {
  const url = `/Job/Filter`;
  const res = await http.post<JobDetailShort[]>(url, filter, config);
  if (res.tag === "ok" && (res.data as unknown) !== "") {
    res.data.forEach((x) => {
      if (x.customerOut !== "") {
        x.customerOut = Number(x.customerOut);
      }
      if (
        Util.defualtToOneCustOutJobs.includes(x.jobType) &&
        !(Number(x.customerOut) > 0)
      ) {
        x.customerOut = 1;
      }
    });
  }
  return res;
};

export type JobUpdateReq = {
  msgId: string;
  ert?: Ert;
  causeCode?: CauseCode;
  jobType?: JobType;
  jobSubType?: JobSubType;
  jobStatus?: JobStatus;
  crewAssignmentStatus?: CrewAssignmentStatus;
  photo?: string;
  jobComments?: string;
};

export type CauseCode = {
  causeCode: string;
  comments: string;
};

export type Ert = {
  ert: string | null;
  comments: string;
};

export type CrewAssignmentStatus = {
  crewAssignmentStatus: string;
  comments: string;
};

export type JobStatus = {
  jobStatus: string;
  comments: string;
};

export type JobType = {
  jobType: string;
  comments: string;
};

export type JobSubType = {
  jobSubType: string;
  jobType: string;
  comments: string;
};

export type JobUpdateRes = {
  msgId: string;
};

export const updateJob = async (job: string, update: JobUpdateReq) => {
  const url = `/Job/Update/${encodeURIComponent(job)}`;

  if (update.causeCode && !update.causeCode.comments.startsWith("OS_")) {
    update.causeCode.comments = "OS_" + update.causeCode.comments;
  }

  if (update.ert && !update.ert.comments.startsWith("OS_")) {
    update.ert.comments = "OS_" + update.ert.comments;
  }

  if (
    update.crewAssignmentStatus &&
    !update.crewAssignmentStatus.comments.startsWith("OS_")
  ) {
    update.crewAssignmentStatus.comments =
      "OS_" + update.crewAssignmentStatus.comments;
  }

  if (update.jobStatus && !update.jobStatus.comments.startsWith("OS_")) {
    update.jobStatus.comments = "OS_" + update.jobStatus.comments;
  }

  if (update.jobType && !update.jobType.comments.startsWith("OS_")) {
    update.jobType.comments = "OS_" + update.jobType.comments;
  }

  if (update.jobSubType && !update.jobSubType.comments.startsWith("OS_")) {
    update.jobSubType.comments = "OS_" + update.jobSubType.comments;
  }

  if (update.jobComments && !update.jobComments.startsWith("OS_")) {
    update.jobComments = "OS_" + update.jobComments;
  }

  const res = await http.post<JobUpdateRes>(url, update, config);
  return res;
};

export type CrewStatus = {
  employeeId: string;
  crewId: string;
  phoneNumber: string;
  name: string;
  crewStatus: string;
};

export const getCrewStatus = async (crewId: string) => {
  const url = `/Crew/${crewId}/Status`;

  const res = await http.get<CrewStatus>(url, config);
  return res;
};

export type HistoricJob = {
  premiseId: string;
  jobId: string;
  aor: string;
  jobSubType: string;
  ert: string;
  region: string;
  prnFlag: string;
  comments: Comments;
  xCord: string;
  yCord: string;
  causeCode: string;
  jobStatus: string;
  jobType: string;
  outageDate: Date;
  RestorationDate: Date;
  circuit: string;
};

export const getJobHistoryByPremiseId = async (premiseId: string) => {
  const url = `/JobHistory`;

  const res = await http.get<HistoricJob[]>(
    url,
    {
      ...config,
      params: { premiseId: premiseId },
    },
    false
  );
  return res;
};

export type LongLatRes = {
  geometries: [Geo | GeoErr];
};

type Geo = {
  x: number;
  y: number;
};

type GeoErr = {
  code: number;
  message: string;
  details: string[];
};

export const getLongLat = async (x: number, y: number) => {
  const url = `${process.env.REACT_APP_API_URL}/esri/services/Utilities/Geometry/GeometryServer/project`;
  const params = {
    inSR: 102121,
    outSR: 4152,
    transformForward: false,
    f: "json",
    geometries: `${x},${y}`,
  };
  const res = await http.get<LongLatRes>(url, {
    params,
    ...headers,
  });
  return res;
};

const AMI_URL = `${process.env.REACT_APP_API_URL}/ami`;

export type AMIJobStatus = {
  jobId: string;
  affected: number;
  restored: number;
  powerOut: number;
  dontKnow: number;
};

export const getAMIJobStatus = async (jobId: string) => {
  const url = `${AMI_URL}/JobStatus/${jobId}`;
  return await http.get<AMIJobStatus>(url, config);
};

export type AMICustomerStatus = {
  jobId: string;
  affected: number;
  restored: number;
  powerOut: number;
  dontKnow: number;
};

export const getAMICustomerStatus = async (customerNumber: string) => {
  const url = `${AMI_URL}/CustomerStatus/${customerNumber}`;
  return await http.get<AMICustomerStatus>(url, config);
};

export type AMISystemStatus = {
  power_On: number;
  power_Off: number;
  unknown: number;
};

export const getAMISystemStatus = async () => {
  const url = `${AMI_URL}/SystemStatus/`;
  return await http.get<AMISystemStatus>(url, config);
};
